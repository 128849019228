<template>
  <div>
    <b-row>
      <b-col>
        <h1>Alta de Facturación</h1>
        <b-form @submit.prevent="submitForm">
          <b-form-group
            label="RFC"
            label-for="rfc">
            <b-form-input
              id="rfc"
              v-model="billing.rfc"
              required
              placeholder="Ingresa el RFC"
            />
          </b-form-group>

          <b-form-group
            label="Razón Social"
            label-for="razonSocial"
          >
            <b-form-input
              id="razonSocial"
              v-model="billing.razonSocial"
              required
              placeholder="Ingresa la razón social"
            />
          </b-form-group>

          <b-form-group label="C.P." label-for="cp">
            <b-form-input
              id="cp"
              v-model="billing.cp"
              required
              placeholder="Ingresa el C.P."
            />
          </b-form-group>

          <b-form-group label="Calle" label-for="calle">
            <b-form-input
              id="calle"
              v-model="billing.calle"
              required
              placeholder="Ingresa la calle"
            />
          </b-form-group>

          <b-form-group label="Número Exterior" label-for="numeroExterior">
            <b-form-input
              id="numeroExterior"
              v-model="billing.numeroExterior"
              required
              placeholder="Ingresa el número exterior"
            />
          </b-form-group>

          <b-form-group label="Número Interior" label-for="numeroInterior">
            <b-form-input
              id="numeroInterior"
              v-model="billing.numeroInterior"
              placeholder="Ingresa el número interior (opcional)"
            />
          </b-form-group>

          <b-form-group label="Colonia" label-for="colonia">
            <b-form-input
              id="colonia"
              v-model="billing.colonia"
              required
              placeholder="Ingresa la colonia"
            />
          </b-form-group>

          <b-form-group label="Localidad" label-for="localidad">
            <b-form-input
              id="localidad"
              v-model="billing.localidad"
              required
              placeholder="Ingresa la localidad"
            />
          </b-form-group>

          <b-form-group label="Municipio" label-for="municipio">
            <b-form-input
              id="municipio"
              v-model="billing.municipio"
              required
              placeholder="Ingresa el municipio"
            />
          </b-form-group>

          <b-form-group label="Estado" label-for="estado">
            <b-form-input
              id="estado"
              v-model="billing.estado"
              required
              placeholder="Ingresa el estado"
            />
          </b-form-group>

          <b-button type="submit" variant="primary"
            >Registrar Facturación</b-button
          >
        </b-form>
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      billing: {
        rfc: '',
        razonSocial: '',
        cp: '',
        calle: '',
        numeroExterior: '',
        numeroInterior: '',
        colonia: '',
        localidad: '',
        municipio: '',
        estado: '',
      },
    }
  },
  methods: {
    submitForm() {
      console.log('Datos del formulario:', this.billing)
      // Aquí puedes hacer una llamada a la API para registrar los datos
    },
  },
}
</script>

<style scoped>
h1 {
  margin-bottom: 20px;
}
</style>
